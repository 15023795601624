import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://11da7216033248ee8025c9b1ad5bffdd@o349165.ingest.sentry.io/4506198329458688",
  debug: false,
  environment: "development",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});